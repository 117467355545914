import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../views/components/Button";
import { PropagateLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { overrideStyle } from "../../utils/utils";
import {
  fetchMerchantDetails,
  messageClear,
} from "../../store/Reducers/registerReducer";

const MerchantDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader, detailsErrorMessage, detailsSuccessMessage } = useSelector(
    (state) => state.register
  );

  const [formData, setFormData] = useState({
    zippy_wallet_number: "",
    // pin: "",
  });

  const inputHandle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    // console.log(state);
    dispatch(fetchMerchantDetails(formData));
  };

  useEffect(() => {
    if (detailsSuccessMessage) {
      toast.success(detailsSuccessMessage);
      dispatch(messageClear());
      navigate("/seller/confirmation");
    }
    if (detailsErrorMessage) {
      toast.error(detailsErrorMessage);
      dispatch(messageClear());
    }
  }, [detailsSuccessMessage, detailsErrorMessage, dispatch, navigate]);

  return (
    <div className="grid items-center justify-center h-screen grid-cols-1 gap-10 px-4 py-8 md:grid-cols-2 md:px-16 font-nunito bg-[#F6F6F6]">
      {/* Start Selling Section */}
      <div className="order-1 p-8 rounded-md md:order-2">
        <h2 className="mb-2 text-xl font-bold font-baskerVille">
          Start Selling On Zippyworld Marketplace
        </h2>

        <p className="mb-6">
          Sign in with your Zippyworld account or create a new account in
          minutes. Selling has never been easier!
        </p>

        <div className="w-3/4 md:w-1/3">
          <Link href="/createpin">
            <Button buttonName={"Create PIN"} buttonType={"submit"} />
          </Link>
        </div>
      </div>

      {/* Login Section */}
      <form
        className="order-2 p-8 bg-white rounded-md shadow-md md:order-1"
        onSubmit={submit}
      >
        <h2 className="mb-5 text-2xl font-bold font-baskerVille">
          Login to your account
        </h2>

        {/* Input Box */}
        <div className="mb-8">
          <label className="font-medium">Phone number:</label>
          <input
            type="text"
            name="zippy_wallet_number"
            id="zippy_wallet_number"
            // value={phonenumber}
            // onChange={(e) => setPhonenumber(e.target.value)}
            onChange={inputHandle}
            value={formData.zippy_wallet_number}
            className="w-full px-4 py-3 text-black border rounded-md focus:outline-none focus:border-blue-500 bg-ashColor/16"
          />
        </div>

        {/* <div className="mb-8">
          <label className="font-medium">PIN:</label>
          <input
            type="password"
            name="pin"
            id="pin"
            onChange={inputHandle}
            value={state.pin}
            className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue-500 bg-ashColor/16"
          />
        </div> */}

        {/* Button */}
        {/* <button className="px-8 py-4 font-bold text-white rounded-md bg-blue hover:bg-blue-600 focus:outline-none">
          Log in
        </button> */}

        <div className="w-1/2 md:w-1/3">
          {/* <Button buttonName={"Log in"} buttonType={"button"} /> */}
          {/* <Button>Login</Button> */}
          {/* <button
            type="submit"
            disabled={loader ? true : false}
            className="w-full py-2 mb-3 text-white rounded-md bg-blue hover:shadow-blue-300/ hover:shadow-lg px-7"
          >
            {loader ? (
              <PropagateLoader color="#fff" cssOverride={overrideStyle} />
            ) : (
              "Log In"
            )}
          </button> */}

          <button
            type="submit"
            className="w-full py-2 mb-3 text-white rounded-md bg-blue hover:shadow-blue-300/ hover:shadow-lg px-7"
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
  );
};

export default MerchantDetails;
