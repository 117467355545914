import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

// Async thunk for adding a category
export const subcategoryAdd = createAsyncThunk(
  "category/subcategoryAdd",
  async (
    { subcategoryName, description, categoryId },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const subcategoryData = { subcategoryName, description, categoryId };
      console.log(subcategoryData);
      const { data } = await api.post(
        "/categories/add_subcategories",
        subcategoryData,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for editing a subcategory
export const editSubCategory = createAsyncThunk(
  "category/editSubCategory",
  async (
    { subcategoryId, subcategoryName, description },
    { rejectWithValue, fulfillWithValue }
  ) => {
    console.log(subcategoryId);
    try {
      const subcategoryData = { subcategoryName, description };
      const { data } = await api.post(
        `/categories/update_subcategories/${subcategoryId}`,
        subcategoryData,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for deleting a subcategory
export const deleteSubCategory = createAsyncThunk(
  "category/deleteSubCategory",
  async (subcategoryId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(
        `/categories/delete_subcategories/${subcategoryId}`,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue({ subcategoryId, message: data.message });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching all subcategories by category id
export const fetchSubcategoriesByCategory = createAsyncThunk(
  "category/fetchSubcategoriesByCategory",
  async (categoryId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/categories/subcategories/${categoryId}`,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue({ categoryId, subcategories: data });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching all subcategories
export const fetchAllSubcategories = createAsyncThunk(
  "category/fetchAllSubcategories",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/categories/all_subcategories", {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subcategoryReducer = createSlice({
  name: "subcategory",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    subcategories: [],
  },
  reducers: {
    clearSubcategories: (state) => {
      state.subcategories = [];
    },
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subcategoryAdd.pending, (state) => {
        state.loader = true;
      })
      .addCase(subcategoryAdd.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(subcategoryAdd.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
        state.subcategories.push({
          id: payload.categoryId,
          categoryName: payload.categoryName,
          description: payload.description,
        });
      })
      .addCase(fetchSubcategoriesByCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchSubcategoriesByCategory.rejected, (state, { payload }) => {
        state.loader = false;
      })
      .addCase(fetchSubcategoriesByCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.subcategories = [
          ...state.subcategories.filter(
            (sub) => sub.category_id !== payload.categoryId
          ),
          ...payload.subcategories.map((sub) => ({
            ...sub,
            category_id: payload.categoryId,
          })),
        ];
      })
      .addCase(fetchAllSubcategories.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchAllSubcategories.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAllSubcategories.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.subcategories = payload;
      })
      .addCase(editSubCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(editSubCategory.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(editSubCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
        const index = state.subcategories.findIndex(
          (sub) => sub.id === payload.id
        );
        if (index !== -1) {
          state.subcategories[index] = {
            ...state.subcategories[index],
            subcategoryName: payload.subcategoryName,
            description: payload.description,
          };
        }
      })
      .addCase(deleteSubCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteSubCategory.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(deleteSubCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
        state.subcategories = state.subcategories.filter(
          (sub) => sub.id !== payload.subcategoryId
        );
      });
  },
});

export const { clearSubcategories, messageClear } = subcategoryReducer.actions;
export default subcategoryReducer.reducer;
