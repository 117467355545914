import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

// Async thunk for adding a product
export const addProduct = createAsyncThunk(
  "add-product/addProduct",
  async (formData, { rejectWithValue, fulfillWithValue }) => {
    console.log(...formData);
    try {
      const response = await api.post("/products/add_product", formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProducts = createAsyncThunk(
  "add-product/fetchProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/products/products_list", {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.get(`/products/product_details/${productId}`, {
        withCredentials: true,
      });
      const { data } = response;
      if (!data.productDetails || !data.productDetails.product_id) {
        throw new Error("Product ID not found in response data");
      }
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ productId, productData }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post(
        `/products/update_product/${productId}`,
        productData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/products/delete_product/${productId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const productReducer = createSlice({
  name: "product",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    products: [],
    totalProduct: 0,
    status: "idle",
    error: null,
    updateSuccessMessage: "",
    updateErrorMessage: "",
    deleteSuccessMessage: "",
    deleteErrorMessage: "",
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.updateSuccessMessage = "";
      state.updateErrorMessage = "";
      state.deleteSuccessMessage = "";
      state.deleteErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProduct.pending, (state) => {
        state.loader = true;
      })
      .addCase(addProduct.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(addProduct.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
        if (Array.isArray(state.products)) {
          state.products.push({
            id: payload.productId,
            merchantId: payload.merchantId,
            productName: payload.productName,
            shortProductName: payload.shortProductName,
            categoryId: payload.categoryId,
            subCategoryId: payload.subCategoryId,
            productDescription: payload.productDescription,
            productPrice: payload.productPrice,
            productModel: payload.productModel,
            productColor: payload.productColor,
            productPicture: payload.productPicture,
            expiryDate: payload.expiryDate,
            vat: payload.vat,
            productDiscountPercentage: payload.productDiscountPercentage,
            productQuantity: payload.productQuantity,
          });
        }
      })
      .addCase(fetchProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProducts.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchProducts.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.products = payload;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProductById.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.productDetails = payload;
      })
      .addCase(fetchProductById.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.loader = false;
        state.updateErrorMessage = "An error occurred, can't update product.";
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.product = payload.product;
        state.updateSuccessMessage = "Product updated successfully";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        if (Array.isArray(state.products)) {
          state.products = state.products.filter(
            (product) => product.id !== action.payload.id
          );
          state.deleteSuccessMessage = action.payload.message;
        }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const { messageClear } = productReducer.actions;
export default productReducer.reducer;
