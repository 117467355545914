import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchMerchantDetails = createAsyncThunk(
  "register/fetchMerchantDetails",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post("/merchant_details", info, {
        withCredentials: true,
      });
      const { data } = response;

      data.result[0].zippy_wallet_number = info.zippy_wallet_number;
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const merchantRegister = createAsyncThunk(
  "register/merchantRegister",
  async (merchantDetails, { rejectWithValue, fulfillWithValue }) => {
    console.log(merchantDetails);
    try {
      const response = await api.post("/register_merchant", merchantDetails, {
        withCredentials: true,
      });
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPin = createAsyncThunk(
  "register/createPin",
  async ({ pin, merchantId }, { rejectWithValue, fulfillWithValue }) => {
    console.log({ pin, merchantId });
    try {
      const response = await api.post(
        `/merchant_pin/${merchantId}`,
        { pin },
        {
          withCredentials: true,
        }
      );
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerReducer = createSlice({
  name: "register",
  initialState: {
    registerSuccessMessage: "",
    registerErrorMessage: "",
    detailsSuccessMessage: "",
    detailsErrorMessage: "",
    pinSuccessMessage: "",
    pinErrorMessage: "",
    loader: false,
    userInfo: "",
    merchantDetails: {
      names: "",
      gender: "",
      email: "",
      address: "",
      bvn: "",
      zippy_wallet_number: "",
      providus_account_no: "",
      status: 1,
    },
    merchantId: "", // Add merchantId to state
  },
  reducers: {
    messageClear: (state, _) => {
      // state.errorMessage = "";
      state.registerSuccessMessage = "";
      state.registerErrorMessage = "";
      state.detailsSuccessMessage = "";
      state.detailsErrorMessage = "";
      state.pinSuccessMessage = "";
      state.pinErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantDetails.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchMerchantDetails.rejected, (state, { payload }) => {
        state.loader = false;
        state.detailsErrorMessage = payload.message || "An error occurred";
      })
      .addCase(fetchMerchantDetails.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.detailsSuccessMessage = payload.message;
        console.log(payload);
        if (payload.status_code === 0) {
          state.detailsSuccessMessage = payload.message;
          state.merchantDetails = {
            ...state.merchantDetails,
            names: payload.result[0].names,
            gender: payload.result[0].gender,
            email: payload.result[0].email,
            address: payload.result[0].address,
            bvn: payload.result[0].bvn,
            zippy_wallet_number: payload.result[0].zippy_wallet_number,
            providus_account_no: payload.result[0].account_no,
            status: 0,
          };
          console.log("Updated state:", state);
        } else {
          state.detailsErrorMessage = payload.message;
        }
      })

      .addCase(merchantRegister.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(merchantRegister.rejected, (state, { payload }) => {
        // if (payload.status_code === 1) {
        state.loader = false;
        state.registerErrorMessage = payload.message;
        // }
      })

      .addCase(merchantRegister.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.merchantDetails && payload.merchantDetails.merchant_id) {
          state.merchantId = payload.merchantDetails.merchant_id;
        }
        state.registerSuccessMessage = payload.message;
        if (payload.status_code === 0) {
          // Store merchant_id
          state.merchantDetails = {
            names: payload.merchantDetails.names,
            gender: payload.merchantDetails.gender,
            email: payload.merchantDetails.email,
            address: payload.merchantDetails.address,
            bvn: payload.merchantDetails.bvn,
            providus_account_no: payload.merchantDetails.providus_account_no,
            status: 0,
          };
        } else {
          state.registerErrorMessage = payload.message;
        }
      })
      .addCase(createPin.pending, (state) => {
        state.loader = true;
      })
      .addCase(createPin.rejected, (state, { payload }) => {
        state.loader = false;
        state.pinErrorMessage = payload.message;
      })
      .addCase(createPin.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.pinSuccessMessage = payload.message;
      });
  },
});

export const { messageClear } = registerReducer.actions;
export default registerReducer.reducer;
